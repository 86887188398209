input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.compress-col {
  margin: 0 0;
}
.compress-col > .col-md-1,
.compress-col > .col-md-2 {
  padding: 0 5px;
}

.form-control[readonly] {
  background-color: unset !important;
  color: #888888;
}

.card {
  margin: 0 0 10px 0;
}
div.form-row > div.col-md-1,
div.form-row > div.col-md-2,
div.form-row > div.col-md-3,
div.form-row > div.col-md-4,
div.form-row > div.col-md-5,
div.form-row > div.col-md-6,
div.form-row > div.col-md-7,
div.form-row > div.col-md-8,
div.form-row > div.col-md-9,
div.form-row > div.col-md-10,
div.form-row > div.col-md-11,
div.form-row > div.col-md-12,
div.form-row > div.col {
  padding: 0 5px;
}

/* Filepicker CSS */
.filepicker {
  font-family: sans-serif;
}

div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #e1e1e1;
  border-radius: 5px;
  min-height: 60px;
  border: 2px dashed #c7c7c7;
}

/* Icon */
.filepicker-file-icon {
  position: relative;

  display: inline-block;

  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;

  color: black;
}
.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;

  width: 29px;
  height: 34px;

  content: '';

  border: solid 2px #7f7f7f;
  border-radius: 2px;
}
.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;

  position: absolute;
  top: 9px;
  left: -4px;

  padding: 0 2px;

  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  background-color: #646464;
}
.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;

  width: 0;
  height: 0;

  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035;
}
