.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}

.q-view{
  width: 100vw;
  left: 0;
  &.active{
    .content {
      left: auto;
      position: fixed;
      .media-object {
        margin-bottom: 10px;
      }
    }
  }
}

.help-desk {
  .media-object {
    margin-bottom: 10px;
  }
}

.navbar-wrapper {
  .navbar-content {
    &.next-scroll {
      height: 100vh;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .hover-blk {
    display: none;
  }
}

.qview-modal {
  &.fade {
    .q-view-modal.modal-dialog {
      transform: translateX(550px);
    }
  }
  &.show {
    padding-right: 0 !important;
    .q-view-modal.modal-dialog {
      transform: none;
    }
  }
}
.q-view-modal {
  margin: 0 0 0 auto !important;
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
    border: none;

    .modal-body {
      flex: 1 1 100%;
      height: 100%;

      .trumbowyg-box,
      .trumbowyg-editor {
        min-height: 110px;
      }
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin: 0 0 0 auto;
    }
  }
}
.pcoded-header .dropleft .dropdown-toggle::before {
  display: none;
}
input.form-control[type='radio']{
  height: 25px;
}

.form-group {
  margin-bottom: 0.1rem;
}

.dims_matrix input{
  width: 60px;
  display: inline-block;
  padding: 2px;
}